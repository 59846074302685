<template>
  <div>
<!--    <NavBar fixed :title="$route.meta.title"></NavBar>-->
    <div class="context-wrap">
      <!--      商标展示-->
      <div class="context-title">为您推荐商标类别</div>
      <div class="context-box">
        <div class="context-list">
          <div class="context-list-row">
            <div>商标名称</div>
            <div>
              {{ trademarkName }}
            </div>
          </div>
          <div class="context-list-row">
            <div>商标图片</div>
            <div>
              <VanImage style="width:50px;height:50px;" :src="trademarkUrl"></VanImage>
            </div>
          </div>
        </div>
      </div>
      <div class="context-title" v-if="this.$route.name === 'queryCategory'">{{ brandCategory[0][0].tabType | tabTypeFilter }}</div>
      <!--      商标注册类别-->
      <template v-for="list in brandCategory" v-if="$route.name === 'queryCategory'">
        <div class="context-box">
          <template v-for="_list in list">
            <div class="context-list">
              <TheBrandCategory :data="_list"></TheBrandCategory>
            </div>
          </template>
        </div>
      </template>
      <template v-for="list in brandCategory" v-if="$route.name === 'recommendCategory'">
        <div class="context-box">
            <div class="context-list">
              <TheBrandCategory :data="list"></TheBrandCategory>
            </div>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import {
  Button,
  Skeleton,
  NavBar,
  Image as VanImage,
  Checkbox,
  Field,
  Toast,
  Icon,
  Radio,
  RadioGroup
} from 'vant'

import TheBrandCategory from "@/views/TheBrandCategory";
import {getTradeMrrkShare} from "@/api/api";

export default {
  name: "orderDetail",
  data() {
    return {
      checkboxColor: '#FF5E00',
      orderDetail: {
        title: '商标续展'
      },
      trademarkUrl: '',
      trademarkName: '',
      brandCategory: [],
      paidOrderInfoTotals: []
    }
  },
  mounted() {
    this.getData();
  },
  filters: {
    tabTypeFilter(value) {
      let props = {0: '精准', 1: '近似'};
      return props[value] || '';
    }
  },
  computed: {

  },
  methods: {
    async getData() {
      Toast.loading({
        message: '加载中...',
        forbidClick: true
      });
      let shareId = this.$route.query.shareId;
      let res = await getTradeMrrkShare({shareId})
      if (res.code === 200) {
        let {trademarkUrl, trademarkName, recommendCategory} = res.data;
        this.trademarkUrl = trademarkUrl;
        this.trademarkName = trademarkName;
        this.brandCategory = JSON.parse(recommendCategory)
        console.log(this.brandCategory);
      }
    }
  },
  components: {
    TheBrandCategory,
    Button, Skeleton, NavBar, VanImage, Checkbox, Field, Toast, Icon, Radio, RadioGroup
  }
}
</script>

<style scoped>
.context-title {
  padding: 10px;
}

.context-wrap {
  overflow: scroll;
  padding-bottom: 60px;
  min-height: calc(100vh - 46px - 60px);
}

.context-box {
  border-radius: 8px;
  background-color: #fff;
  display: flex;
  margin: 6px 10px;
  align-items: center;
}

.context-title {
  font-size: 14px;
}

.context-list {
  width: 100%;
  padding-bottom: 7px;
}

.context-list-item {
  border-bottom: .5px solid #e6e6e6;
  padding: 14px;
  display: flex;
  font-size: 13px;
  justify-content: space-between;
}

.context-list-item > div {
  margin-right: 32px;
}

.context-list-item > div:last-child {
  margin-right: 0;
}

.context-list-item:first-child {
  padding-top: 0;
}

.context-list-item:last-child {
  border-bottom: none;
  padding-bottom: 0;
}

.flex {
  display: flex;
  flex-wrap: wrap;
}

.context-list-cell > span {
  margin-right: 14px;
}

.context-list-cell {

}


.poster-box {
  width: 50px;
  height: 50px;
  margin: 14px;
  overflow: hidden;
}

.title {
  font-size: 16px;
  font-weight: bold;
  padding: 16px;
}

.subtitle {
  color: #666;
  font-size: 13px;
}

.money {
  color: #FF5E00
}

.form-content {
  padding: 0 14px;
  position: relative;
}

.form-item-title {
  margin: 9px 0;
  align-items: center;
  font-size: 13px;
}

.form-item {
  margin: 9px 0;
  overflow: hidden;
  display: flex;
  width: 100%;
  align-items: center;
  font-size: 13px;
}

.form-item:last-child {
  margin-bottom: 0;
}

.form-item:first-child {
  margin-top: 0;
}

.form-label {
  width: 80px;
  color: #666;
  font-size: 13px;
}

.form-value {
  font-size: 13px;
}

.context-list-row {
  padding: 9px 14px;
  display: flex;
  font-size: 13px;
  justify-content: space-between;
  align-items: flex-start;
}

.context-list-row > div:first-child {
  width: 70px;
}

.context-list-row > div:last-child {
  flex: 1;
}

.context-list-row > div {
  display: flex;
  align-items: center;
}

.context-list-row:last-child {
  border-bottom: none;
  padding-bottom: 0;
}

.button-copy {
  position: absolute;
  right: 14px;
  bottom: 5px;
}

.button-pay {
  padding: 0 30px;
}

.bottom-menu {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  overflow: hidden;
  display: flex;
  justify-content: space-between;
  background-color: #fff;
  box-shadow: 0px 0px 3px 1px rgba(0, 0, 0, 0.1);
  height: 60px;
  align-items: center;
}

.bottom-menu > div {
  padding: 0 14px;
}
</style>
